import shortid from "shortid";

/** Преобразует модель с локациями */
export const transformLocations = locations => {
	let arr;
	let index = 0;
	if (!locations) {
		return [];
	}
	arr = locations.map(
		({ title, placeMark = [], phone = [], fax = [], workTime, image }) => {
			return {
				id: shortid.generate(),
				index: index++,
				title,
				image,
				placeMark: [placeMark.x, placeMark.y],
				phone:
					phone && phone.length ? phone.map(item => item.number) : undefined,
				fax: fax && fax.length ? fax.map(item => item.number) : undefined,
				workTime:
					workTime && workTime.length
						? workTime.map(item => item.time)
						: undefined
			};
		}
	);
	return arr;
};

/** Преобразует байты в читаемые значения */
export const readableBytes = kilobytes => {
	const formateedSize = parseInt(kilobytes, 10);
	if (!formateedSize || formateedSize === 0) {
		return `${kilobytes} kB`;
	}

	const rankBase = 1024;

	const i = Math.abs(Math.floor(Math.log(formateedSize) / Math.log(rankBase)));
	const sizes = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	return `${parseFloat(String(formateedSize / rankBase ** i)).toFixed(0)} ${
		sizes[i]
	}`;
};
