import React from "react";
import Markdown from "@components/markdown";
import { cmsServer } from "@constants/common";

import "./faq.scss";
import CirclePlus from "@components/circle-plus";

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from "react-accessible-accordion";
import { connect } from "react-redux";
import { File } from "@components/file";
import { readableBytes } from "@utils";

const FAQComponent = ({ faqs }) => {
	return (
		<section className="full-width faq">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2>Часто задаваемые вопросы</h2>
					</div>
				</div>
			</div>
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<Accordion
							allowZeroExpanded
							allowMultipleExpanded
							className="accordion accordion_theme_faq"
						>
							{faqs &&
								!!faqs.length &&
								faqs.map(({ question, answer, order, documents }) => (
									<AccordionItem key={order}>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="container">
													<CirclePlus className="circle-plus" />
													<span>{question}</span>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<div className="markdown-faq">
												<Markdown source={answer} />
											</div>
											<div className="faq__documents">
												{documents &&
													!!documents.length &&
													documents.map((doc, i) => {
														const { size, name: fullName, url } =
															doc.file || {};

														if (!size || !url) {
															return null;
														}

														const [, extension] = fullName.split(".");

														return (
															<File
																key={i}
																name={doc.name}
																type={extension}
																size={readableBytes(size)}
																link={`${cmsServer}${url}`}
																className="faq__documents__file"
															/>
														);
													})}
											</div>
										</AccordionItemPanel>
									</AccordionItem>
								))}
						</Accordion>
					</div>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = props => {
	const { staticData: { faqs } = {} } = props;
	return { faqs };
};

const FAQ = connect(
	mapStateToProps,
	null
)(FAQComponent);

export default FAQ;
